const API_URL =
  "https://new-medi-digital.vercel.app/api/send-mail/manus/notification"

export const sendEmail = async data => {
  try {
    const newUser = {
      registrationDate: new Date().toLocaleString(),
    }

    const contentMessage = `Dear Team,\n\nWe are pleased to inform you that a new user has registered on our platform. Please find the details below:\n\nName: ${data.name}\nEmail: ${data.email}\nServices: ${data.services}\nPhone Number: ${data.phone}\nCompany name: ${data.companyName}\nRegistration Date: ${newUser.registrationDate}\n\nApi Speadsheets link : https://api.apispreadsheets.com/data/20790`

    const formData = {
      toEmail: "tradespeople@manusapp.com",
      content: contentMessage,
      message: "New User Registration",
    }

    const submitData = { formData }

    const response = await fetch(API_URL, {
      method: "POST",
      body: JSON.stringify(submitData),
      headers: {
        "content-type": "application/json",
      },
      mode: "no-cors",
    })

    const responseData = await response.json()
    console.log("Email sent:", responseData)
  } catch (error) {
    console.error("Error sending email:", error)
  }
}
