import * as Yup from "yup"

const phoneRegex = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
const personalInformationSchema = Yup.object().shape({
  gender: Yup.string().required("This field is required"),
  name: Yup.string()
    .max(50, "First name is too long")
    .required("First name is required"),
  surname: Yup.string()
    .max(50, "Last name is too long")
    .required("Last name is required"),
  address: Yup.string().required("Address is required"),
  postcode: Yup.number().required("Postcode is required"),
  phone: Yup.string().required("Phone is required"),
  birthday: Yup.date().required("Birthday is required"),
  profileImage: Yup.mixed().required("Profile image is required"),
  driverLicenseImage: Yup.mixed().required("This image is required"),
  passportImage: Yup.mixed().required("This image is required"),
  nacionality: Yup.string().required("Nacionality is required"),
})
const signUpSchema = Yup.object().shape({
  name: Yup.string().required("This field is required"),
  companyName: Yup.string().required("This field is required"),
  telephoneNumber: Yup.string().required("This field is required").matches(phoneRegex, 'Phone number is not valid'),
  email: Yup.string()
    .email("Enter a valid email")
    .required("This field is required"),
  website: Yup.string().required("This field is required"),
  location: Yup.mixed().required("Postcode is required"),
  services: Yup.string().required("This field is required"),
})

const professionalInformationSchema = Yup.object().shape({
  test: Yup.string().required("This field is required"),
})

const declarations = Yup.object().shape({
  test2: Yup.string().required("Test2 is required"),
})

const validationSchema = [
  signUpSchema,
  personalInformationSchema,
  professionalInformationSchema,
  declarations,
]

export default validationSchema
