import React, { useState } from "react"
import ValidationSchema from "./validationSchema"
import SignUp from "./registration-steps/sign-up"
import { sendEmail } from "../../utils/sendmail"
// import FormStepper from "../common/formStepper"
// import config from "gatsby-plugin-config"
// import PersonalInformation from "./registration-steps/personal-information"
// import ProfessionalInformation from "./registration-steps/professional-information"
// import Declarations from "./registration-steps/declarations"

const validationSchema = ValidationSchema
const STEPS = {
  SignUp: 1,
  // PersonalInformation: 1,
  // ProfessionalInformation: 2,
  // Declarations: 3,
}
function RegisterForm() {
  const [formSent, setFormSent] = useState("")
  const [step, setStep] = useState(STEPS.SignUp)
  const [stepValues, setStepValues] = useState({})
  const nextStep = () => setStep(step => step + 1)
  const [loading, setLoading] = useState(false)

  const currentDate = new Date()
  const day = String(currentDate.getDate()).padStart(2, "0")
  const month = String(currentDate.getMonth() + 1).padStart(2, "0")
  const year = currentDate.getFullYear()

  const formattedDate = `${day}/${month}/${year}`

  const prevStep = props => {
    setStepValues(stepValues => {
      return { ...stepValues, [step]: props }
    })
    setStep(step => step - 1)
  }

  const onSubmit = (values, form) => {
    setLoading(true)
    fetch("https://api.apispreadsheets.com/data/20790/", {
      method: "POST",
      body: JSON.stringify({
        data: [
          {
            name: values.name,
            "Company Name": values.companyName,
            email: values.email,
            website: values.website,
            location: values.location,
            services: values.services,
            "Telephone Number": values.telephoneNumber,
            action: "",
            date: formattedDate,
          },
        ],
      }),
    }).then(res => {
      setLoading(false)
      if (res.status === 201) {
        sendEmail({
          name: values.name,
          services: values.services,
          email: values.email,
          phone: values.telephoneNumber,
          location: values.location,
          companyName: values.companyName,
        })
        setFormSent("sent")
      } else {
        setFormSent("failed")
        // ERROR
      }
    })
    // if (step === STEPS.Declarations) {
    //   console.log(stepValues)
    // } else {
    //   setStepValues(stepValues => {
    //     return { ...stepValues, [step]: values }
    //   })
    //   nextStep()
    // }
  }

  return (
    <div className="w-full lg:w-8/12 bg-white mx-auto pt-5 px-7 lg:px-20 mt-10 lg:mt-20 -mb-24 pb-48 rounded-3xl">
      {/* <FormStepper step={step} /> */}
      {{
        1: (
          <SignUp
            step={STEPS.SignUp}
            onSubmit={onSubmit}
            validationSchema={validationSchema[STEPS.SignUp - 1]}
            values={stepValues[STEPS.SignUp]}
            formSent={formSent}
            loading={loading}
          />
        ),
        // 1: (
        //   <PersonalInformation
        //     step={STEPS.PersonalInformation}
        //     onSubmit={onSubmit}
        //     validationSchema={validationSchema[STEPS.PersonalInformation - 1]}
        //     values={stepValues[STEPS.PersonalInformation]}
        //   />
        // ),
        // 2: (
        //   <ProfessionalInformation
        //     step={STEPS.ProfessionalInformation}
        //     onSubmit={onSubmit}
        //     validationSchema={
        //       validationSchema[STEPS.ProfessionalInformation - 1]
        //     }
        //     values={stepValues[STEPS.ProfessionalInformation]}
        //     prevStep={prevStep}
        //   />
        // ),
        // 3: (
        //   <Declarations
        //     step={STEPS.Declarations}
        //     onSubmit={onSubmit}
        //     validationSchema={validationSchema[STEPS.Declarations - 1]}
        //     prevStep={prevStep}
        //     values={stepValues[STEPS.Declarations]}
        //   />
        // ),
      }[step] || <div />}
    </div>
  )
}
export default RegisterForm

//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
