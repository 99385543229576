import React from "react"
import { Formik, Form, Field } from "formik"
import Select from "react-select"
import InputError from "../../common/inputError"
import * as Yup from "yup"

const servicesOptions = [
  { value: "handyman", label: "Handyman" },
  { value: "plumber", label: "Plumber" },
  { value: "electrician", label: "Electrician" },
  { value: "carpenter", label: "Carpenter" },
  { value: "roofer", label: "Roofer" },
  { value: "painter_decorator", label: "Painter and decorator" },
]

const initialValues = {
  name: "",
  companyName: "",
  telephoneNumber: "",
  email: "",
  website: "",
  location: "",
  services: "",
}
const validationSchema = Yup.object().shape({
  name: Yup.string().required("This field is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  telephoneNumber: Yup.string()
    .matches(/^[0-9]+$/, "Only digits are allowed")
    .min(10, "Phone number must be at least 10 digits")
    .required("Phone number is required"),
  companyName: Yup.string().required("This field is required"),
  website: Yup.string().required("This field is required"),
  location: Yup.string()
    .matches(/^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i, "Invalid postcode")
    .required("Postcode is required"),
  services: Yup.string().required("This field is required"),
})

function signUp(props) {
  return (
    <Formik
      enableReinitialize
      initialValues={props.values ? props.values : initialValues}
      validationSchema={validationSchema}
      onSubmit={props.onSubmit}
    >
      {prop => {
        return (
          <Form className="mb-0">
            <div
              className="js-step block"
              data-active-classes="block"
              data-inactive-classes="hidden"
            >
              <div className="flex flex-col lg:flex-row mt-6">
                <div className="w-full lg:w-1/2 lg:mr-6">
                  <label className="text-gray-300 text-sm mr-4">Name *</label>
                  <Field
                    type="text"
                    name="name"
                    id="name"
                    className="w-full border border-gray-300 rounded-full py-3 px-7 mt-1 outline-none text-sm"
                  />
                  <InputError name="name" />
                </div>
                <div className="w-full lg:w-1/2 lg:ml-6 mt-5 lg:mt-0">
                  <label className="text-gray-300 text-sm mr-4">Email *</label>
                  <Field
                    type="text"
                    name="email"
                    id="email"
                    className="w-full border border-gray-300 rounded-full py-3 px-7 mt-1 outline-none text-sm"
                  />
                  <InputError name="email" />
                </div>
              </div>
              <div className="flex flex-col lg:flex-row mt-6">
                <div className="w-full lg:w-1/2 lg:mr-6">
                  <label className="text-gray-300 text-sm mr-4">
                    Company Name *
                  </label>
                  <Field
                    type="text"
                    name="companyName"
                    id="companyName"
                    className="w-full border border-gray-300 rounded-full py-3 px-7 mt-1 outline-none text-sm"
                  />
                  <InputError name="companyName" />
                </div>

                <div className="w-full lg:w-1/2 lg:ml-6 mt-5 lg:mt-0">
                  <label className="text-gray-300 text-sm mr-4">
                    Postcode *
                  </label>
                  <Field
                    id="location"
                    name="location"
                    className="w-full border border-gray-300 rounded-full py-3 px-7 mt-1 outline-none text-sm"
                  />
                  <InputError name="location" />
                </div>
              </div>

              <div className="flex flex-col lg:flex-row mt-6">
                <div className="w-full lg:w-1/2 lg:mr-6">
                  <p className="text-gray-300 text-sm -mb-1">Services *</p>
                  <Select
                    options={servicesOptions}
                    name="services"
                    className="py-2 text-sm"
                    placeholder="Select service"
                    onChange={option =>
                      prop.setFieldValue("services", option.value)
                    }
                  />
                  <InputError name="services" />
                </div>
                <div className="w-full lg:w-1/2 lg:ml-6">
                  <label className="text-gray-300 text-sm mr-4">
                    Website *
                  </label>
                  <Field
                    id="website"
                    name="website"
                    className="w-full border border-gray-300 rounded-full py-3 px-7 mt-1 outline-none text-sm"
                  />
                  <InputError name="website" />
                </div>
              </div>

              <div className="flex flex-col lg:flex-row mt-6">
                <div className="w-full lg:w-1/2 lg:mr-6 mt-5 lg:mt-0">
                  <label className="text-gray-300 text-sm mr-4">
                    Mobile Number *
                  </label>
                  <Field
                    id="telephoneNumber"
                    name="telephoneNumber"
                    className="w-full border border-gray-300 rounded-full py-3 px-7 mt-1 outline-none text-sm"
                  />
                  <InputError name="telephoneNumber" />
                </div>
                <div className="w-full md:w-1/2">
                  <div className="w-full relative">
                    {props.formSent === "sent" ? (
                      <div className="text-xs text-blue-400 font-semibold text-left w-full   whitespace-nowrap  absolute ">
                        <span className="w-fit-content flex  mx-auto">
                          Your registration email is sent.
                        </span>{" "}
                      </div>
                    ) : props.formSent === "failed" ? (
                      <div className="text-xs text-red-400 font-semibold text-left w-full md:w-fit-content mt-3">
                        Your email is not sent. Please try again or contact us
                        at hello@manusapp.com
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <button
                    disabled={props.loading && prop.isSubmitting ? true : false}
                    className={`w-full hover:bg-gray-300 text-white text-sm px-12 py-3 rounded-full whitespace-nowrap mt-7 mb-0 focus:outline-none ${
                      props.loading && prop.isSubmitting
                        ? "bg-gray-300"
                        : "bg-blue-400"
                    }`}
                    type="submit"
                  >
                    {props.loading && prop.isSubmitting
                      ? "Processing..."
                      : "Sign Up"}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default signUp
