import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import RegisterForm from "../components/become-a-tradesman/registerForm"
import InvestorPopup from "../components/common/investorPopup"

function BecomeATradesman() {
  const [investPopup, setInvestPopup] = useState(false)
  useEffect(() => {
    setTimeout(() => setInvestPopup(true), 7000)
  }, [])

  return (
    <Layout>
      <SEO
        title="Become a tradesperson"
        description="Register online now providing the informations requested below. We'll get in touch with you shortly with the next steps"
      />
      <Header class="main-container fixed w-full z-50 top-0" />
      <div className="main-container pt-20 lg:pt-28 bg-gray-200 -mb-20 lg:-mb-24 pb-28">
        <div className="container px-3 md:px-6">
          <div className="w-full lg:w-7/12 mx-auto">
            <h2 className="text-4xl lg:text-5xl text-blue-400 lg:text-blue-400 font-medium leading-tight text-center mb-2 lg:mb-3 mt-12">
              Join Manus. Nearby jobs. Whenever you want.
            </h2>
            <p className="text-gray-600 text-sm-base lg:text-base leading-normal font-medium text-center mb-0">
              Be a part of the Manus revolution! Say goodbye to driving miles
              between jobs, mornings or afternoons with no work, or gaps in your
              diary caused by cancellations.<br></br>
              Register with Manus, and you’ll have immediate work, within 5
              miles of where you’re located, whenever you want it. There’s no
              bidding, direct callouts, and working for you 24/7, 365 days of
              the year. When you’re not available… simply log out of the app.
              Fill out the form, and we’ll be in touch to get you registered.
            </p>
          </div>
          <div className="flex text-center w-max mx-auto relative mt-5 lg:mt-10">
            <div className="bg-green-400 h-2 w-12 rounded-full mr-4"></div>
            <div className="bg-blue-400 h-2 w-20 rounded-full"></div>
          </div>
          <RegisterForm />
        </div>
        {investPopup ? (
          <InvestorPopup setInvestPopup={setInvestPopup} />
        ) : (
          <></>
        )}
      </div>
    </Layout>
  )
}

export default BecomeATradesman
